import React, { Component } from 'react';
import './Button.css';

export default class OptionButtons extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { options, onClick } = this.props;
    return (
      <div>
        {options.map(function(option, index){
          return <p key={index}><button className="form-button" onClick={() => {onClick(option.destination)}}>{option.label}</button></p>;
        })}
      </div>
    );
  }
}

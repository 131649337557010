import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import fireApp from '../firebase';
import GuideDictionary from '../dictionaries/GuideDictionary';
import Header from '../components/navigation/Header';
import AnimatedText from '../components/AnimatedText';
import GuideControls from '../components/GuideControls';
import OptionButtons from '../components/form/OptionButtons';
import TextInput from '../components/form/TextInput';
import bowl from '../assets/bowly_blinky.gif';
import './styles/GuidePage.css';

const firebaseSigninUIConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  credentialHelper: window.firebaseui.auth.CredentialHelper.NONE,
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => (false)
  }
};

class GuidePage extends Component {
  constructor(props) {
    super(props);
    this.guideText = React.createRef();
    this.handleTap = this.handleTap.bind(this);
    this.goToLastStepInHistory = this.goToLastStepInHistory.bind(this);
    this.goToNextStepInHistory = this.goToNextStepInHistory.bind(this);
  }

  state = {
    animationRolling: false,
    currentStep: 'welcomeEarly1',
    stepHistory: ['welcomeEarly1'],
    historyPointer: 0,
    isSignedIn: false,
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        console.log("user is", user);
        if(!!user && this.state.currentStep==='welcomeEarly3b') { this.goToNewStep('welcomeEarly4'); }
        this.setState({
          isSignedIn: !!user,
          user: user ? { uid: user.uid, displayName: user.displayName, email: user.email } : null,
        });
      }
    );

    const userKeyFromStorage = localStorage.getItem('userKey');
    console.log("userKey from storage", userKeyFromStorage);
    this.setState({
      userKey: userKeyFromStorage,
    });

    const stepFromStorage = localStorage.getItem('currentGuideStep');
    const stepHistoryFromStorage = JSON.parse(localStorage.getItem('stepHistory')) || null;
    const historyPointerFromStorage = localStorage.getItem('historyPointer') || null;
    if(stepFromStorage) {
      this.moveToStep(stepFromStorage, stepHistoryFromStorage, historyPointerFromStorage);
    }
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  saveStepState(stepName, stepHistory, historyPointer) {
    this.setState({ 
      currentStep: stepName,
      stepHistory: stepHistory,
      historyPointer: historyPointer,
    });

    console.log("about to save to local", stepName, stepHistory, historyPointer);
    localStorage.setItem('currentGuideStep', stepName);
    // Need to JSON decode / encode this step history to put it inn local Storage.
    localStorage.setItem('stepHistory', JSON.stringify(stepHistory));
    localStorage.setItem('historyPointer', historyPointer);

    if(this.state.user) {
      const db = firebase.firestore();
      db.collection('users').doc(this.state.user.uid).set({
        "currentGuideStep": stepName,
        "stepHistory": stepHistory,
        "historyPointer": historyPointer,
      });
    }
  }

  displayStep(stepName) {
    const step = GuideDictionary[stepName];
    this.guideText.current.newText(step.text);
  }

  // If no historyPointer is passed in, just copy from current state.
  moveToStep(stepName, stepHistory = this.state.stepHistory, historyPointer = this.state.historyPointer) {
    console.log("in move to step", stepName);
    this.saveStepState(stepName, stepHistory, historyPointer);
    this.displayStep(stepName);
  }

  goToNewStep(stepName) {
    let { stepHistory, historyPointer } = this.state;

    // IF the historyPointer isn't pointing to the tail of the list, then override the rest of the list and put the new step into this position.
    console.log("going to new step", stepHistory, historyPointer);
    if(historyPointer < stepHistory.length - 1) {
      // Create new history array, which is a copy of the existign array up to the point of the current pointer
      stepHistory = stepHistory.slice(0, historyPointer + 1);
    }

    // IF the new step is different from the last step in stepHistory, add it.
    if(stepHistory[historyPointer] !== stepName) {
      stepHistory.push(stepName);
      historyPointer++;
    }
    this.moveToStep(stepName, stepHistory, historyPointer);
  }

  goToLastStepInHistory() {
    let { stepHistory, historyPointer } = this.state;
    // do NOT go to last step if there's no last step to go to
    if(historyPointer === 0 || !stepHistory[historyPointer-1]) { return }

    historyPointer--;
    const stepToGoTo = stepHistory[historyPointer];

    this.moveToStep(stepToGoTo, stepHistory, historyPointer);
  }

  goToNextStepInHistory() {
    let { stepHistory, historyPointer } = this.state;

    // do NOT go to next step if there's no next step to go to
    if(historyPointer >= stepHistory.length-1) { return }

    if(stepHistory.length - 1 < historyPointer) {
      // Don't do this if we are already pointing to the tail of the stepHistory array.
      return;
    }
    historyPointer++;
    const stepToGoTo = stepHistory[historyPointer];

    this.moveToStep(stepToGoTo, stepHistory, historyPointer);
  }

  handleTap() {
    if(this.guideText.current.state.animating) {
      this.guideText.current.completeAnimation();
    } else {
      console.log("buggaboo");
    }
  }

  onNextStepClicked() {
    const {historyPointer, stepHistory, currentStep} = this.state;
    console.log("this.guide", this.guideText);
    if(this.guideText.current.state.animating) {
      this.guideText.current.completeAnimation();
    } else if(historyPointer < stepHistory.length-1) {
      this.goToNextStepInHistory();
    } else if(GuideDictionary[currentStep].options.length === 1) {
      this.goToNewStep(GuideDictionary[currentStep].options[0].destination);
    }
  }

  onAnimationComplete() {
    this.setState({
      animationRolling: false,
    });
  }

  onAnimationRolling() {
    this.setState({
      animationRolling: true,
    });
  }

  onSubmitText = (value) => {
    const {currentStep, user} = this.state;
    // Can now look at the current fieldId to decide where to store this information
    const params = GuideDictionary[currentStep].textInput;
    const collection = params.collection || 'default';
    const prompt = GuideDictionary[currentStep].text;

    localStorage.setItem(params.fieldId, value);

    // Don't try to save anything to the firestore if we don't have a user to associate it to
    if(!user) { return }

    let obj = {
      key: params.fieldId,
      value: value,
      prompt: prompt,
    };
    // If you wanted to do key: value this is how you could, instead I'm breaking it down.
    // obj[params.fieldId] = value;

    const userRef = firebase.firestore().collection('users').doc(user.uid);
    userRef.collection(collection).add(obj);

    this.goToNewStep(params.destination);
  }

  render() {
    const {currentStep, historyPointer, stepHistory} = this.state;
    console.log("rendering with state ", this.state);

    const animatedText = <p className="guide-text" onClick={this.handleTap}>
      <AnimatedText 
        ref={this.guideText} 
        onAnimationComplete={() => {this.onAnimationComplete()}}
        onAnimationRolling={() => {this.onAnimationRolling()}}>
        {GuideDictionary[currentStep].text}
      </AnimatedText>
    </p>;

    const onClickOption = (clicked) => {
      this.goToNewStep(clicked);
    }

    const options = GuideDictionary[currentStep].options ?
      <OptionButtons options={GuideDictionary[currentStep].options} onClick={onClickOption} /> : null;

    const input = GuideDictionary[currentStep].textInput ?
      <TextInput params={GuideDictionary[currentStep].textInput} onSubmit={this.onSubmitText} /> : null;
    
    const signin = GuideDictionary[currentStep].showSignin && !this.state.isSignedIn ?
      <StyledFirebaseAuth uiConfig={firebaseSigninUIConfig} firebaseAuth={fireApp.auth()}/> : null;

    const FormFields = () => (
      <div className={ this.state.animationRolling ? "lowpacity" : null}>
        {signin}
        {options}
        {input}
      </div>
    );

    const AnimatedBowl = () => (<img src={bowl} className="bowl-large animated wobble" alt="logo" />);

    const nextAvailable = this.state.animationRolling || 
      historyPointer < stepHistory.length-1 ||
      (GuideDictionary[currentStep].options && GuideDictionary[currentStep].options.length === 1);
    console.log("also determine", GuideDictionary[currentStep].options);
    
    const GuideControlsWithParams = () => (
      <GuideControls
        currentStep={GuideDictionary[currentStep]}
        onGoBack={this.goToLastStepInHistory}
        onGoNext={() => {this.onNextStepClicked()}}
        backAvailable={historyPointer !== 0 && stepHistory[historyPointer-1]}
        nextAvailable={nextAvailable}
      />
    );

    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    const isDesktop = width >= 768;

    return (
      <div>
        <Header hideBowl={!isDesktop} />
        { isDesktop ?
          <div>
            <div className="split right">
              <div className="centered">
                {animatedText}
                <FormFields />
              </div>
            </div>
            <div className="split left">
              <div className="centered">
                <AnimatedBowl />
                <GuideControlsWithParams />
              </div>
            </div>
          </div> : <div>
            <div className="not-split">
              <div className="centered-mobile">
                {animatedText}
                <FormFields />
              </div>
            </div>
            <div className="top-right">
            <div className="centered">
              <AnimatedBowl />
            </div>;
            </div>
            <div className="sticky-bottom">
              <GuideControlsWithParams />
            </div>
          </div>
        }
      </div>
    );
  }
}


export default GuidePage;

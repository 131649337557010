import React from 'react';
import Modules from '../dictionaries/Modules';

export default class GuideControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      timeElapsed: 0,
      currentStep: props.currentStep || null,
      currentModule: props.currentStep.module ? Modules[props.currentStep.module] : null
    };
  }

  render() {
    const { currentStep, backAvailable, nextAvailable } = this.props;
    console.log("back and next", backAvailable, nextAvailable);
    const currentModule = currentStep.module ? Modules[currentStep.module] : null;

    const center = {
      textAlign: 'center',
    };

    return (
      <div style={center}>
        <p className="guide-text"><b>module</b> {currentModule ? currentModule.title : "unknown"}</p>
        <p className="guide-text">
          { backAvailable ? <span onClick={() => this.props.onGoBack()}><b>&laquo; </b></span> : <span>&laquo; </span>}
          <b>step </b>
          {currentStep.step} of {currentModule.steps} 
          { nextAvailable ? <span onClick={() => this.props.onGoNext()}><b> &raquo;</b></span> : <span> &raquo;</span>}
        </p>
      </div>
    );
  }
}

import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../components/navigation/Header';
import { Link } from "react-router-dom";
import './styles/VisionPage.css';

class VisionPage extends Component {
  render() {
    return (
      <div>
        <div id="content-wrap">
        <Header darkMode />
        <div className="vision-hero">
          <div className="vision-hero-header">
            <p>We envision a world where communities come together to make sure every bowl is filled.</p>
            <p><Link className="vision-cta-button" to="/guide">be a pioneer</Link></p>
          </div>
        </div>
        <div className="block-one">
          <Row>
            <Col className="header">
              <p>We are creating the foundations for a new <i>cooling</i> economic model with three core elements to ensure we are looking after ourselves, one another, and our home on Earth.</p>
            </Col>
          </Row>
          <Row className="three-panel">
            <Col className="section" xs={12} md={4}>
              <h2>1. Internalizing temperature</h2>
              Our economy today optimizes for growth measured in dollars. It ignores its impact on the global temperature. We believe that by gathering data on the interactions of agents in an economy we can come to a better understanding of the temperature impact, giving us a new foundation for an economy that cools the Earth.
            </Col>
            <Col className="section" xs={12} md={4}>
              <h2>2. Intelligent matching</h2>
              The use of currency makes our economic transactions impersonal and wasteful. Leveraging trust and technology, we can build a new economy based on what individuals are willing to offer freely to not only meet basic needs, but help more people work towards their highest aspiration.
            </Col>
            <Col className="section" xs={12} md={4}>
              <h2>3. Aspiration over attachment</h2>
              In order for individuals to truly and successfully participate in the new economy, they will need to embrace a path of personal growth. They will need to trust that they new economy will guide them not to material success but to success in their highest aspirations.
            </Col>
          </Row>
        </div>
        </div>
      </div>
    );
  }
}

export default VisionPage;

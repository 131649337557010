export const GuideAspirations = {
  aspirations1: {
    text: "Bowl is an ambitious project. Our highest aspiration as an organization is to create an entirely new economy, where community trust replaces the need for currency. This is challenging, but possible, right now, and has major implications for the future of Earth's ecosystems.",
    module: "aspirations",
    step: 1,
    options: [
      {
        label: 'got it',
        destination: 'aspirations2',
      },
    ],
  },
  aspirations2: {
    text: "Are you interested in learning more about Bowl's ambitious plan and how your participation will help us?",
    module: "aspirations",
    step: 2,
    options: [
      {
        label: 'yes',
        destination: 'aspirations2',
      },
      {
        label: 'no, I get it',
        destination: 'aspirations3',
      },
    ],
  },
  aspirations3: {
    text: "In the Bowl economy, we want to help you strive towards your highest aspiration, and this is also at the heart of why we want you to trust Bowl.",
    module: "aspirations",
    step: 3,
    options: [
      {
        label: 'yes',
        destination: 'aspirations4',
      },
    ],
  },
  aspirations4: {
    text: "Do you feel ready to explain your highest aspirations to us, or do you want to learn a little bit more about what we mean by that?",
    module: "aspirations",
    step: 4,
    options: [
      {
        label: 'I am ready',
        destination: 'aspirations5',
      },
      {
        label: 'I\'d love to learn more about highest aspiration',
        destination: 'aspirations4',
      },
    ],
  },
  aspirations5: {
    text: "Great. First of all, tell us what you think your highest aspiration in life is.",
    module: "aspirations",
    step: 5,
    textInput: {
      size: 'small',
      placeholderText: 'type here',
      fieldId: 'highAspiration',
      collection: 'aspirations',
      destination: 'aspirations6',
      submitLabel: 'next'
    }
  },
  aspirations6: {
    text: "Thank you. Next question, more specifically, what's one thing that you love spending time doing? Something that you'd do full time for work if you didn't have to worry about making money?",
    module: "aspirations",
    step: 6,
    textInput: {
      size: 'small',
      placeholderText: 'type here',
      fieldId: 'moneylessWork',
      collection: 'aspirations',
      destination: 'aspirations7',
      submitLabel: 'next'
    }
  },
  aspirations7: {
    text: "What's something else you love doing that you think others can derive value from?",
    module: "aspirations",
    step: 7,
    textInput: {
      size: 'small',
      placeholderText: 'type here',
      fieldId: 'othersValue',
      collection: 'aspirations',
      destination: 'aspirations8',
      submitLabel: 'next'
    }
  },
  aspirations8: {
    text: "Ok. thank you so much for all of that. If you didn't fill these out, you will be able to find them later in your profile.",
    module: "aspirations",
    step: 8,
    options: [
      {
        label: 'sounds good',
        destination: 'welcomeEarly5',
      },
    ],
  },
}
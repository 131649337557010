export const GuideTrustPhilosophy = {
  trust1: {
    text: "At Bowl we believe that the fundamental trust relationship between humans and technology has been broken.",
    module: "trust",
    step: 1,
    options: [
      {
        label: 'next',
        destination: 'trust2',
      },
    ],
  },
  trust2: {
    text: "We've trusted tech companies with our personal data, creativity, and life stories. In exchange, we've gotten greedy user experiences hungry to consume as much of our time, energy, and attention as they can.",
    module: "trust",
    step: 2,
    options: [
      {
        label: 'next',
        destination: 'trust3',
      },
    ],
  },
  trust3: {
    text: "At Bowl we respect your data, your time, and your attention. We don't want you to give us any of those things if you don't feel you can trust us, not only to deliver value to you, but to guide you towards your highest aspiration.",
    module: "trust",
    step: 3,
    options: [
      {
        label: 'next',
        destination: 'trust4',
      },
    ],
  },
  trust4: {
    text: "We don't want you to spend more time staring at a screen than is absolutely necessary, we promise.",
    module: "trust",
    step: 4,
    options: [
      {
        label: 'yep',
        destination: 'trust5',
      },
    ],
  },
  trust5: {
    text: "What we want is for the time you spend here (thank you) to be in the services of meeting your needs in the real world, so that you are unhindered to do work in accordance with your highest aspiration.",
    module: "trust",
    step: 5,
    options: [
      {
        label: 'sounds good',
        destination: 'welcomeEarly2',
      },
      {
        label: 'tell me more about what Bowl does',
        destination: 'trust6',
      },
    ],
  },
  trust6: {
    text: "Often times, it's the need to earn money that causes us to spend much of our waking hours doing work that doesn't align with our highest aspiration. ",
    module: "trust",
    step: 6,
    options: [
      {
        label: 'next',
        destination: 'trust7',
      },
    ],
  },
  trust7: {
    text: "We kid ourselves into thinking that our work aligns with our highest aspiration, because we need to justify doing something that also makes money. If we are being honest, in many cases, we'd be doing something very different if money truly wasn't an object.",
    module: "trust",
    step: 7,
    options: [
      {
        label: 'next',
        destination: 'trust8',
      },
    ],
  },
  trust8: {
    text: "But money is an object. Because we need money to pay our rent, buy lunch, and order the occasional Uber. In today's world, for the most part, the need to make money to sustain basic needs is the reality.",
    module: "trust",
    step: 8,
    options: [
      {
        label: 'next',
        destination: 'trust9',
      },
    ],
  },
  trust9: {
    text: "Enter Bowl. For some people all of the time, and for all people some of the time, we want to make it possible to meet basic needs without money, enabling pursuit of the highest purpose. ",
    module: "trust",
    step: 9,
    options: [
      {
        label: 'next',
        destination: 'trust10',
      },
    ],
  },
  trust10: {
    text: "This is why we think you can trust us. But if you don't that's fine, it's your decision and it shouldn't be any other way. ",
    module: "trust",
    step: 10,
    options: [
      {
        label: 'next',
        destination: 'welcomeEarly2',
      },
    ],
  },
}
import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OfferRequestDetails from './OfferRequestDetails';
import '../pages/styles/MatchesPage.css';
import bus from '../assets/bus.png';
import clock from '../assets/clock.png';
import house from '../assets/house.png';
import bowl from '../assets/bowl_logo_transp.png';

export default class MatchRow extends React.Component {
  state = {
    expanded: this.props.expanded || false,
    showAcceptForm: false,
    showDeclineForm: false,
  };

  expand() {
    this.setState({
      expanded: true,
    })
  }

  collapse() {
    this.setState({
      expanded: false,
    })
  }

  showAcceptForm() {
    this.setState({
      showAcceptForm: true,
    });
  }

  showDeclineForm() {
    this.setState({
      showDeclineForm: true,
    });
  }

  hideForms() {
    this.setState({
      showDeclineForm: false,
      showAcceptForm: false,
    });
  }

  render() {
    const { expanded, showAcceptForm, showDeclineForm } = this.state;
    const { 
      matchedOfferRequest,
      offerRequest,
      reviewable,
      editable,
      matchable,
      acceptOrDeclinable,
      onMatch
    } = this.props;
    console.log("matched offer request", matchedOfferRequest);
    const {
      id,
      offerOrRequest,
      createdAt,
      type,
    } = offerRequest;

    let graphic = bowl;

    switch(type) {
      case 'shelter':
        graphic = house;
        break;
      case 'transport':
        graphic = bus;
        break;
      case 'time':
        graphic = clock;
        break;
      case 'food':
        graphic = bowl;
        break;
      default:
        break;
    }

    // If this match was created in the last minute, then we want to show a "highlight" animation on it.
    let rowStyle = "match-preview";
    const oneMinute = 60 * 1000;
    const nowTimestamp = (new Date()).getTime();
    const createdAtTimestamp = createdAt.seconds * 1000;
    if(nowTimestamp - createdAtTimestamp < oneMinute) {
      rowStyle = "match-preview highlight-new";
    }

    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    const isDesktop = width >= 768;

    return (
      <div>
      <Row className={rowStyle}>
        <Col xs={3} md={2} className="match-image-section">
          <img src={graphic} width="100%" alt="logo" />
        </Col>
        <Col xs={8} md={8} className="match-content-section">
          <OfferRequestDetails offerRequest={offerRequest} expanded={expanded} />
        </Col>
        <Col xs={1} md={2} className="match-link-section">
          {
            isDesktop ? <div>
              { expanded ? 
                <p><button className="form-button edit-view-button" onClick={() => {this.collapse()}}>less</button></p>
                : <p><button className="form-button edit-view-button" onClick={() => {this.expand()}}>view</button></p> }
              { editable ? <p><Link className="form-button edit-view-button" to={`${offerOrRequest}/${type}/${id}`}>edit</Link></p> : null }
              { matchable ? <p><button className="form-button edit-view-button" onClick={() => {onMatch()}}>match</button></p> : null}
              { reviewable ? <div>
                <p><Link className="form-button edit-view-button" to={`view/${matchedOfferRequest.offerOrRequest}/${matchedOfferRequest.id}`}>review</Link></p>
              </div> : null }
            </div> : <span><Link className="borderless-button" to={`view/${reviewable ? matchedOfferRequest.offerOrRequest : offerOrRequest}/${reviewable ? matchedOfferRequest.id : id}`}>&raquo;</Link></span>
          }
        </Col>
      </Row>
        { acceptOrDeclinable && !(showAcceptForm || showDeclineForm) ? <Row>
          <p><button className="form-button edit-view-button" onClick={() => {this.showAcceptForm()}}>accept this match </button></p> 
          <p><button className="form-button edit-view-button" onClick={() => {this.showDeclineForm()}}>decline this match</button></p>
        </Row>
        : null}
        {
          showAcceptForm ? <Row><form onSubmit={this.handleSubmit}>
          <p className="big-label-text">Write a short message to the person you are matching with:</p>
          <p className="offer-request-field">
            <input
              name="maxDays"
              type="text"
              value='pleeb'
              onChange={() => {}}
              placeholder='e.g. pleeeeeeb'
            />
          </p>
          <p><input className="form-button offer-request-submit" type="submit" value='confirm' />&nbsp;<button className="borderless-button" onClick={() => {this.hideForms()}}>cancel</button></p>
          </form></Row> : null
        }
        {
          showDeclineForm ? <Row><form onSubmit={this.handleSubmit}>
          <p className="big-label-text">Briefly explain why this wasn't a match:</p>
          <p className="offer-request-field">
            <input
              name="maxDays"
              type="text"
              value='pleeb'
              onChange={() => {}}
              placeholder='e.g. pleeeeeeb'
            />
          </p>
          <p><input className="form-button offer-request-submit" type="submit" value='confirm' />&nbsp;<button className="borderless-button" onClick={() => {this.hideForms()}}>cancel</button></p>
          </form></Row> : null
        }
      </div>
    );
  }
}

import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../styles/OfferRequest.css';
import '../styles/MatchesPage.css';
import firebase from 'firebase';
import Header from '../../components/navigation/Header';
import MatchPreviewRow from '../../components/MatchPreviewRow';
import MatchRow from '../../components/MatchRow';


class MatchmakerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      request: null,
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        // In this case we should be checking if the user has matchmaking permissions
        // and eventually if they have specific matchmaking permissions for this "BOWL" (community group)
        const requestRef = firebase.firestore().collection('requests');
        requestRef.orderBy('createdAt', 'desc').limit(1).get().then(snapshot => {
          console.log("snapshot docs dis werkin?", snapshot.docs[0].data());
          if(snapshot.empty) {
            console.log('no request found');
          } else {
            console.log('found a request', snapshot.docs[0]);
            const request = {id: snapshot.docs[0].id, ...snapshot.docs[0].data()};
            this.setState({
              request: request
            });

            const offersRef = firebase.firestore().collection('offers');
            offersRef
              .where('userId', '==', user.uid)
              .where('type', '==', request.type)
              .orderBy('createdAt', 'desc').get().then(snapshot => {
              let offersData = {}
              snapshot.forEach(doc => {
                offersData[doc.id] = doc.data();
              });
              this.setState({
                offers: offersData,
              });
            })
            .catch(err => {
              console.log('Error getting orders for matchmaker', err);
            });
          }
        })
        .catch(err => {
          console.log('Error retrieving request to match');
        });

        this.setState({
          user: user ? { uid: user.uid, displayName: user.displayName, email: user.email } : null,
        });
      }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  match(offerId) {
    console.log("match happened", offerId);
    let data = {
      requestId: this.state.request.id,
      requestUserId: this.state.request.userId,
      offerId: offerId,
      offerUserId: this.state.offers[offerId] ? this.state.offers[offerId].userId : null,
      status: 'pending',
    }
    data.createdAt = new Date();
    data.updatedAt = new Date();
    console.log("aaadata", data);
    // return;

    const ref = firebase.firestore().collection('matches');
    ref.add(data);
  }

  render() {
    console.log("state", this.state);
    if(!this.state.user || !this.state.offers || !this.state.request) { return null; }
    const { offers } = this.state;

    return (
      <div>
        <Header />
        <div className="matches-container">
          <div className="matches-wrapper">
            <div>
              <h3 className="match-section-header">Request to be matched</h3>
              <MatchRow expanded offerRequest={this.state.request} />
            </div>
            <div>
              <h3 className="match-section-header">Candidate Offers</h3>
              <div className="match-list">
                {Object.keys(offers).map(offerId => (<MatchRow offerRequest={offers[offerId]} matchable key={offerId} onMatch={() => {this.match(offerId)}} />))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MatchmakerPage;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './styles/OfferRequest.css';
import './styles/MatchesPage.css';
import firebase from 'firebase';
import Header from '../components/navigation/Header';
import MatchRow from '../components/MatchRow';
import OfferRequestDetails from '../components/OfferRequestDetails';


class OfferRequestViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    const type = this.props.match ? this.props.match.params.type : null;
    const offerRequestId = this.props.match ? this.props.match.params.offerRequestId : null;

    console.log("type and thang", type, offerRequestId);
    if (!type || !offerRequestId) { return; }

    const otherType = type === 'request' ? 'offer' : 'request';
    this.setState({
      type: type,
      otherType: otherType,
      offerRequestId: offerRequestId,
    });

    // Data to get:
    // Get the given offerRequest by ID, if it belongs to the authorized user.
    // Find any matches corresponding to that offerRequest
    // If there is a match to that offer request, get the corresponding offer or request that is matched

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        console.log("what you got", offerRequestId);
        const offerRequestRef = firebase.firestore().collection(`${type}s`).doc(offerRequestId);
        offerRequestRef.get().then(offerRequest => {
          if (!offerRequest.exists) {
            console.log('No such offer/request document!', offerRequestId);
          } else {
            console.log("offewr ", offerRequest);
            if(offerRequest.data().userId !== user.uid) {
              // Throw an error
              console.log("wrong user for this offer/request");
              return;
            }
            this.setState({
              offerRequest: {id: offerRequest.id, ...offerRequest.data()},
            });
          }
        })
        .catch(err => {
          console.log('Error getting offerRequest to view', err);
        });

        const matchRef = firebase.firestore().collection('matches');

        // Need to think about: there could be more than one match for a given offer/request.
        // In the case that there is we only want to select the most recent and active match.
        matchRef.where(`${type}Id`, '==', offerRequestId).get().then(snapshot => {
          snapshot.forEach(match => {
            console.log(match.id, ' =>', match.data());

            const matchOfferRequestRef = firebase.firestore().collection(`${otherType}s`).doc(match.data()[`${otherType}Id`]);
            matchOfferRequestRef.get().then(matchOfferRequest => {
              if (!matchOfferRequest.exists) {
                console.log('No such matching offer/request!');
              } else {
                console.log('Document data:', matchOfferRequest.data());
                this.setState({
                  matchOfferRequest: {id: matchOfferRequest.id, ...matchOfferRequest.data()},
                });
              }
            })
            .catch(err => {
              console.log('Error getting document', err);
            });

            this.setState({
              match: {id: match.id, ...match.data()},
            })
          });
        })
        .catch(err => {
          console.log('Error getting MATCH in offer request view', err);
        });
      }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { type, otherType, offerRequest, match, matchOfferRequest } = this.state;
    if (!type || !offerRequest) { return null; }

    console.log("state of this thing", this.state);

    return (
      <div>
        <Header />
        <div className="matches-container">
          <div className="matches-wrapper">
            <p><Link to='/matches'>&laquo; back</Link> <b>{offerRequest.title}</b></p>
            <div>
              {
                !match  || !matchOfferRequest ? 
                  <Row className="match-preview-empty">
                    <Col className="empty-text">
                      <span className="action-buttons"><button className="form-button edit-view-button" onClick={() => {this.collapse()}}>edit</button></span>
                      <span className="action-buttons"><button className="form-button edit-view-button" onClick={() => {this.collapse()}}>cancel</button></span>
                    </Col>
                  </Row> 
                  :
                  <div key={match.id}> 
                    <b>Your {type} matched with the following {otherType}:</b>
                    <MatchRow offerRequest={matchOfferRequest} acceptOrDeclinable />
                  </div>
              }
            </div>
            <div>
              <OfferRequestDetails offerRequest={offerRequest} noTitle expanded/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OfferRequestViewPage;

import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './styles/OfferRequest.css';
import './styles/MatchesPage.css';
import firebase from 'firebase';
import Header from '../components/navigation/Header';
import MatchRow from '../components/MatchRow';


class MatchesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        const matchesRef = firebase.firestore().collection('matches');
        matchesRef.where('requestUserId', '==', user.uid).get().then(snapshot => {
          let matchesData = [];
          snapshot.forEach(doc => {
            console.log(doc.id, ' =>', doc.data());

            const offerRef = firebase.firestore().collection('offers').doc(doc.data().offerId);
            offerRef.get().then(offer => {
              if (!offer.exists) {
                console.log('No such offer document!');
              } else {
                console.log('Document data:', offer.data());
                let matchOffers = this.state.matchOffers || {};
                matchOffers[offer.id] = offer.data();
                this.setState({
                  matchOffers: matchOffers,
                });
              }
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
            matchesData.push({id: doc.id, ...doc.data()});
          });
          this.setState({
            matches: matchesData,
          });
        })
        .catch(err => {
          console.log('Error getting MATCH MATCH MATCH', err);
        });

        const offersRef = firebase.firestore().collection('offers');
        offersRef.where('userId', '==', user.uid).orderBy('createdAt', 'desc').get().then(snapshot => {
          let offersData = {}
          snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
            offersData[doc.id] = { id: doc.id, offerOrRequest: 'offer', ...doc.data() };
          });
          this.setState({
            offers: offersData,
          });
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });

        const requestsRef = firebase.firestore().collection('requests');
        requestsRef.where('userId', '==', user.uid).orderBy('createdAt', 'desc').get().then(snapshot => {
          let requestsData = {}
          snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
            requestsData[doc.id] = { id: doc.id, offerOrRequest: 'request', ...doc.data() };
          });
          this.setState({
            requests: requestsData,
          });
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
        this.setState({
          user: user ? { uid: user.uid, displayName: user.displayName, email: user.email } : null,
        });
      }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    console.log("state", this.state);
    if(!this.state.user || !this.state.offers || !this.state.requests) { return null; }
    const { offers, requests, matchOffers } = this.state;

    return (
      <div>
        <Header />
        <div className="matches-container">
          <div className="matches-wrapper">
            <div>
              <h3 className="match-section-header">Matches</h3>
              {
                this.state.matches.length < 1 || !this.state.matchOffers || this.state.matchOffers.length < 1 ? 
                  <Row className="match-preview-empty">
                    <Col className="empty-text">
                      you don't have any matches right now.
                    </Col>
                  </Row> 
                  :
                  this.state.matches.map(match => (matchOffers[match.offerId] ? <div key={match.id}> 
                    <b>Your request for "{requests[match.requestId].title}" matched with the following offer:</b>
                    <MatchRow matchedOfferRequest={requests[match.requestId]} offerRequest={matchOffers[match.offerId]} reviewable />
                  </div> : null))
              }
            </div>
            <div>
              <h3 className="match-section-header">Active Requests</h3>
                {Object.keys(requests).map((key) => (<MatchRow editable offerRequest={requests[key]} key={key} />))}
            </div>
            <div>
              <h3 className="match-section-header">Active Offers</h3>
                {Object.keys(offers).map((key) => (<MatchRow editable offerRequest={offers[key]} key={key} />))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MatchesPage;

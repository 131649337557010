import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import fireApp from '../firebase';
import firebase from 'firebase';
import Header from '../components/navigation/Header';
import './styles/firebaseui-styling.global.css';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/guide',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ]
};

class LoginPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header />
        <div className="login-container">
          <p className="login-header">how would you like to sign in?</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={fireApp.auth()}/>
        </div>
      </div>
    );
  }
}

export default LoginPage;

import React, { Component } from 'react';
import firebase from 'firebase';
import DatePicker from "react-datepicker";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import '../styles/OfferRequest.css';
import Header from '../../components/navigation/Header';

const OFFER_TYPE = 'shelter';

class ShelterOfferPage extends Component {
  constructor(props) {
    super(props);

    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 7);

    this.state = {
      submissionComplete: false,
      user: null,
      title: '',
      shelterType: 'roomInPlace',
      durationType: 'once',
      startDate: new Date(),
      endDate: defaultEndDate,
      maxDays: '',
      location: '',
      description: '',
      notes: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.setState({
          user: user ? { uid: user.uid, displayName: user.displayName, email: user.email } : null,
        });
      }
    );

    // If there is a prop for the ID of the shelter offer that we are editing
    const editId = this.props.match ? this.props.match.params.editId : null;

    // IF there's an edit ID, look up that offer and load in it's data instead of the blank defaults.
    if (!editId) { return; }
    const offerRef = firebase.firestore().collection('offers').doc(editId);
    offerRef.get().then(offer => {
      if (!offer.exists) {
        console.log('No such offer document!');
      } else {
        console.log('Document data:', offer.data());
        const offerData = offer.data();
        this.setState({
          title: offerData.title,
          shelterType: offerData.shelterType,
          durationType: offerData.durationType,
          startDate: new Date(offerData.startDate.seconds * 1000),
          endDate: new Date(offerData.endDate.seconds * 1000),
          maxDays: offerData.maxDays,
          location: offerData.location,
          description: offerData.description,
          notes: offerData.notes,
          offerId: offer.id,
        });
      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  handleSubmit(event) {
    event.preventDefault();
    if(!this.state.user) { return }

    let data = Object.assign({}, this.state);
    delete data.user;
    data.userId = this.state.user.uid;
    data.type = OFFER_TYPE;
    data.createdAt = new Date();
    data.updatedAt = new Date();

    const ref = firebase.firestore().collection('offers');
    if(this.state.offerId) {
      // Edit an existing offer
      ref.doc(this.state.offerId).set(data);
    } else {
      ref.add(data);
    }

    this.setState({ submissionComplete: true });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  setStartDate = date => {
    this.setState({   
      startDate: date
    });
  };

  setEndDate = date => {
    this.setState({   
      endDate: date
    });
  };

  render() {
    if (this.state.submissionComplete === true) {
      return <Redirect to='/matches' />
    }

    return (
      <div>
        <Header />
        <div className="login-container">
          <p className="offer-request-form-header">{this.state.offerId ? "edit shelter offer" : "offer shelter"}</p>
          <div className="offer-request-form-wrapper">
            <form onSubmit={this.handleSubmit}>
              <p className="big-label-text">please give a short, descriptive title for this shelter offer.</p>
              <p className="offer-request-field">
                <input 
                  name="title" 
                  type="text" 
                  value={this.state.title} 
                  onChange={this.handleInputChange} 
                  placeholder='e.g. Private Room in Oakland, CA'
                />
              </p>
              <p className="big-label-text">which best describes the layout of this shelter?</p>
              <p className="offer-request-field">
                <select
                  name="shelterType"
                  value={this.state.shelterType}
                  className="select-css"
                  onChange={this.handleInputChange}>
                  <option value="roomInPlace">A room in my place</option>
                  <option value="entirePlace">My entire place</option>
                </select>
              </p>
              <p className="big-label-text">which best describes the availability of this shelter?</p>
              <p className="offer-request-field">
                <select 
                  name="durationType"
                  value={this.state.durationType}
                  className="select-css"
                  onChange={this.handleInputChange}>
                  <option value="once">Available once for a fixed period of time</option>
                  <option value="onceFlexible">Available once for a flexible period of time</option>
                  <option value="continuous">Available continuously</option>
                </select>
              </p>
              {
                this.state.durationType !== 'continuous' ? <div>
                  <p className="big-label-text">when is the beginning of the window when you can offer shelter?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={date => this.setStartDate(date)}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                    />
                  </div>
                  <p className="big-label-text">Up until what date can you offer shelter?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={date => this.setEndDate(date)}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      minDate={this.state.startDate}
                    />
                  </div> 
                </div> : null
              }
              
              <p className="big-label-text">What's the longest continuous stay (in days) you'd like someone staying with you?</p>
              <p className="offer-request-field">
                <input
                  name="maxDays"
                  type="text"
                  value={this.state.maxDays}
                  onChange={this.handleInputChange}
                  placeholder='e.g. 3'
                />
              </p>
              <p className="big-label-text">Where is your place located?</p>
              <p className="offer-request-field">
                <input
                  name="location"
                  type="text"
                  value={this.state.location}
                  onChange={this.handleInputChange}
                  placeholder='e.g. 2330 Brook Ln. Oakland, CA'
                />
              </p>
              <p className="big-label-text">Write a short description of the space</p>
              <p className="offer-request-field">
                <input
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.handleInputChange}
                  placeholder='e.g. couch in the living room'
                />
              </p>
              <p className="big-label-text">Is there anything else you want us to know about this you, this offer, or your ideal guest?</p>
              <p className="offer-request-field">
                <input
                  name="notes"
                  type="text"
                  value={this.state.notes}
                  onChange={this.handleInputChange}
                  placeholder='e.g. hoping to house a non-smoker'
                />
              </p>
              <p><input className="form-button offer-request-submit" type="submit" value='submit' /></p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ShelterOfferPage;

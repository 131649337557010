import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import OldPage from './pages/OldPage';
import StoryPage from './pages/StoryPage';
import VisionPage from './pages/VisionPage';
import GuidePage from './pages/GuidePage';
import LoginPage from './pages/LoginPage';
import OfferPage from './pages/OfferPage';
import RequestPage from './pages/RequestPage';
import MatchesPage from './pages/MatchesPage';
import OfferRequestViewPage from './pages/OfferRequestViewPage';
import ShelterOfferPage from './pages/forms/ShelterOfferPage';
import TransportOfferPage from './pages/forms/TransportOfferPage';
import TimeOfferPage from './pages/forms/TimeOfferPage';
import FoodOfferPage from './pages/forms/FoodOfferPage';
import ShelterRequestPage from './pages/forms/ShelterRequestPage';
import TransportRequestPage from './pages/forms/TransportRequestPage';
import TimeRequestPage from './pages/forms/TimeRequestPage';
import FoodRequestPage from './pages/forms/FoodRequestPage';
import MatchmakerPage from './pages/admin/MatchmakerPage';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/old/:path" component={OldPage} />
        <Route path="/old" component={OldPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/vision" component={VisionPage} />
        <Route path="/story" component={StoryPage} />
        <Route path="/guide" component={GuidePage} />
        <Route path="/offer/shelter/:editId?" component={ShelterOfferPage} />
        <Route path="/offer/transport/:editId?" component={TransportOfferPage} />
        <Route path="/offer/time/:editId?" component={TimeOfferPage} />
        <Route path="/offer/food/:editId?" component={FoodOfferPage} />
        <Route path="/offer" component={OfferPage} />
        <Route path="/request/shelter/:editId?" component={ShelterRequestPage} />
        <Route path="/request/transport/:editId?" component={TransportRequestPage} />
        <Route path="/request/time/:editId?" component={TimeRequestPage} />
        <Route path="/request/food/:editId?" component={FoodRequestPage} />
        <Route path="/request" component={RequestPage} />
        <Route path="/matches" component={MatchesPage} />
        <Route path="/view/:type/:offerRequestId" component={OfferRequestViewPage} />
        <Route path="/admin/matchmaker" component={MatchmakerPage} />
        <Route path="/" component={VisionPage} />
      </Switch>
    );
  }
}


export default App;

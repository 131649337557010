import React, { Component } from 'react';
import './styles/OldStyles.css';
import Header from '../components/navigation/Header';
import { Link } from "react-router-dom";

const Index = () => (
  <div id="main">
    <p className="h1">Welcome to Bowl!</p>
    <p className="body">This is the old prototype for Bowl. We will be migrating away over the coming weeks but for now you can use it for tracking and sharing. We are happy to have you onboard. Please fill out the first two forms below in the order they are presented (if you haven't already). You only need to do these each once, after which you can start participating in the sharing economy.</p>
    <p className="h2">1. first <a href="https://airtable.com/shrbo6SIv06FdEz59" target="_blank">sign up here</a></p>
    <p className="h2">2. next <a href="https://airtable.com/shrRyVdRegeMsYiLY" target="_blank">tell us about yourself</a></p>
    <p className="h2">3. then <Link to="/old/share">get involved in our sharing economy</Link></p>
    <p className="h2">4. and <Link to="/old/track">track your weekly activities</Link></p>
    <p className="body">Thank you so much for your participation. We are very grateful. For more information on why we are doing this <Link to="/old/why">click here</Link>.</p>
  </div>
);

const Why = () => (
	<div id="main">
		<p class="h1">Why Bowl?</p>
		<p class="body">We are motivated as a team to leverage technology and human ingenuity to radically face the global climate crisis. We believe nothing short of a new economic system will be powerful enough to make the sweeping changes required to halt global warming.</p>
		<p class="body">We envision a new <i>cooling</i> economy with three core elements to create an alternative to the default economy that is heating up the earth, and to practice our aspiration to care for life on our Earth-home.</p>
		<p class="h2">1. Internalizing temperature</p>
		<p class="body">Our economy today optimizes for growth measured in dollars. It ignores its impact on the global temperature. We believe that by gathering data on the interactions of agents in an economy to meet their needs we can come to a better understanding of the temperature impact of various behaviors, giving us a new foundation for grounding an economy optimized for cooling the Earth.</p>
		<p class="h2">2. Intelligent matching</p>
		<p class="body">The use of currency makes our economic transactions impersonal and wasteful. Leveraging faith and technology, we believe we can build a new economy based primarily on what individuals are willing to offer freely to not only meet basic needs, but help more people work on something they love, or pursue spiritual growth.</p>
		<p class="h2">3. Aspiration over attachment</p>
		<p class="body">In order for individuals to truly and successfully participate in the new economy, they will need to embrace a path of personal and spiritual growth. They will need to trust that they new economy will guide them not to material success but to success in their highest aspirations. For these reasons, we believe that prototyping with a community like the one here at Monastic Academy will be a useful starting point. We are deeply grateful for your participation.</p>
		<p class="body"><Link to="/old">back to home</Link></p>
	</div>
);

const FirstTime = () => (
	<div id="main">
		<p className="h1">New user signup</p>
		<p className="body">We are happy to have you onboard. Please fill out the two forms below in the order they are presented. You only need to do these each once, after which you can start doing your daily and weekly entries starting tomorrow.</p>
		<p className="h2">1. first <a href="https://airtable.com/shrbo6SIv06FdEz59" target="_blank">sign up here</a></p>
		<p className="h2">2. next <a href="https://airtable.com/shrRyVdRegeMsYiLY" target="_blank">tell us about yourself</a></p>
		<p className="body">That's it for now. When you have time, please begin filling out weekly entries <Link to="old/track">here</Link>.</p>
	</div>
);

const Share = () => (
  <div id="main">
		<p className="h1">Bowl Sharing Economy</p>
		<p className="body">Project Bowl envisions building an Earth-cooling economy from first principles. At the core of this system is our collective capacity to share resources. We empower those with access to resources such as shelter or transportation to offer these to those in their value-aligned community who are in need. For more information on why we are doing this, <Link to="/old/why">click here</Link></p>
		<p className="body">Currently we are prototyping transportation and shelter sharing - to make an offer or request for either please follow the links below.</p>
		<p className="h2"><Link to="/old/transport">Share transportation</Link></p>
		<p className="h2"><Link to="/old/shelter">Share shelter</Link></p>
		<p className="h2"><Link to="/old/time">Share time</Link></p>
		<p className="body">Thank you for being a part of the earliest phase of our project. If you are looking for the tracking homepage you can find it <Link to="/old/track">here</Link>.</p>
	</div>
);

const Track = () => (
	<div id="main">
		<p className="h1">Bowl Tracking</p>
		<p className="body">Thank you. You are helping us gather information that will allow us to build an economic model of the Monastic Academy showing how value flows within your community, and allowing us to make suggestions to lower your overall impact on the global temperature. For more information on why we are doing this, <Link to="/old/why">click here</Link>.</p>
		<p className="body">We ask you to provide us with data by filling the following forms. </p>
		<p className="h2">1. new users <Link to="/old/firsttime">do this first</Link></p>
		<p className="subscript">(you'll only have to do this once)</p>
		<p className="h2">2. once a week <Link to="/old/track-weekly">do this</Link></p>
	</div>
);

const TrackWeekly = () => (
	<div id="main">
		<p className="h1">Ongoing entries</p>
		<p className="body">These weekly entries provide the core data for our system. They log how you interacted with the internal and external economies to provide for your basic needs.</p>
		<p className="body">We want to learn about three things: meals you ate outside of the two daily meals at Maple, any trips or transportation that you took, and other miscellaneous lifestyle expenses you might have had.</p>
		<p className="body">You can fill these out once a week, looking back at your activities over the previous week, or you can add them over the week as you go.</p>
		<p className="h2"><a href="https://airtable.com/shr3RnmbJr7SbbA8X" target="_blank">Add a new meal entry</a></p>
		<p className="subscript">(do this once per outside meal you've had)</p>
		<p className="h2"><a href="https://airtable.com/shr4UkrsnwVwrhp0E" target="_blank">Add a new transportation entry</a></p>
		<p className="subscript">(do this once per trip you took. or not at all if you didn't)</p>
		<p className="h2"><a href="https://airtable.com/shrzpgVLmMfiGsRVo" target="_blank">Add a new misc. expense entry</a></p>
		<p className="subscript">(once per misc expense or you can group them, don't worry about being comprehesive here)</p>
		<p className="body"><Link to="/old">back to home</Link></p>
	</div>
);

const Transport = () => (
  <div id="main">
		<p className="h1">Transportation Share</p>
		<p className="body">Welcome to transport sharing with Bowl. The goal of this project is to allow the sharing of transportation, so that every drive is used to its fullest capacity. The process is simple, you either offer or request transport, and we'll send you an email if there's a match. When there's a match both parties have an opportunity to review and confirm or decline before the share is locked in.</p>
		<p className="body">If you have never signed up with us before (in the old system) you can get started by <a href="https://airtable.com/shrTLG4MKXBGdmJz3">clicking here</a>. Once you have done that, you can proceed to offer or request transport by clicking the corresponding links below. </p>
		<p className="h2"><a href="https://airtable.com/shr5VaqFGWdtFaWEN" target="_blank">Offer transportation</a></p>
		<p className="h2"><a href="https://airtable.com/shrpRrFcmfQP4BTRC" target="_blank">Request transportation</a></p>
		<p className="body">Thank you for being a part of the earliest phase of our project. To view all sharing projects <Link to="/share">click here</Link>.</p>
	</div>
);

const Shelter = () => (
  <div id="main">
		<p className="h1">Shelter Share</p>
		<p className="body">Welcome to shelter sharing with Bowl. The goal of this project is to allow the sharing of homes within communities that have shared values. The process is simple, you either offer or request shelter, and we'll send you an email if there's a match. When there's a match both parties have an opportunity to review and confirm or decline before the shelter share is locked in.</p>
		<p className="body">If you have never signed up with us before you can get started by <a href="https://airtable.com/shrTLG4MKXBGdmJz3">clicking here</a>. Once you have done that, you can proceed to offer or request shelter by clicking the corresponding links below. </p>
		<p className="h2"><a href="https://airtable.com/shraUQooQrsy0MgPJ" target="_blank">Offer shelter</a></p>
		<p className="h2"><a href="https://airtable.com/shr7bvqADZ81Izzsc" target="_blank">Request shelter</a></p>
		<p className="body">Thank you for being a part of the earliest phase of our project. To view all sharing projects <Link to="/share">click here</Link>.</p>
	</div>
);

const Time = () => (
  <div id="main">
		<p className="h1">Time Share</p>
		<p className="body">Welcome to time sharing with Bowl. We think of time as anything you'd offer or request from another person where the primary resource is not something tangible such as shelter or transportation - but consists of a person's time. This could be anything from someone to help you move a couch, to weekly tutoring sessions on a new language and beyond. When there's a match both parties have an opportunity to review and confirm or decline before the share is locked in.</p>
		<p className="body">If you have never signed up with us before you can get started by <a href="https://airtable.com/shrTLG4MKXBGdmJz3">clicking here</a>. Once you have done that, you can proceed to offer or request transport by clicking the corresponding links below. </p>
		<p className="h2"><a href="https://airtable.com/shrDOteVKRW6aPNH1" target="_blank">Offer time</a></p>
		<p className="h2"><a href="https://airtable.com/shrE9p34ja8jyAert" target="_blank">Request time</a></p>
		<p className="body">Thank you for being a part of the earliest phase of our project. To view all sharing projects <Link to="/share">click here</Link>.</p>
	</div>
);

class OldPage extends Component {
  render() {
    const path = this.props.match ? this.props.match.params.path : null;
    let content = <Index />;
    switch(path) {
      case 'why':
        content = <Why />;
        break;
      case 'first-time':
        content = <FirstTime />;
        break;
      case 'share':
        content = <Share />;
        break;
      case 'track':
        content = <Track />;
        break;
      case 'track-weekly':
        content = <TrackWeekly />;
        break;
      case 'transport':
        content = <Transport />;
        break;
      case 'shelter':
        content = <Shelter />;
        break;
      case 'time':
        content = <Time />;
        break;
      default:
        break;
    }

    return (
      <div>
        <Header />
        <div id="wrap">
          {content}
        </div>
      </div>
    );
  }
}

export default OldPage;

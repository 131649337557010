import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../components/navigation/Header';
import './styles/StoryPage.css';
import iainAndVienna from '../assets/iain_and_vienna.jpg';
import rebootWin from '../assets/reboot_win.png';
import youthPlanning from '../assets/youth_planning.jpg';
import monasteryWork from '../assets/monastery_work.jpg';

class StoryPage extends Component {
  render() {
    return (
      <div id="story-container">
        <div id="story-wrap">
        <Header />
        <div className="story-hero">
          <div className="story-hero-header">
            The story of project bowl
          </div>
        </div>
        <div className="story-section">
          <Row>
            <Col className="story-panel" xs={12} md={6}>
              <h2>1. Team Foundations</h2>
              Our team leader is Vienna Looi. She helped lead an election reform movement in her home country of Malaysia before moving to the US to seek asylum. Caring deeply about the Earth and its peoples, she has been working on economic innovations for climate over the past year.
            </Col>
            <Col className="story-panel" xs={12} md={6}>
              <img src={iainAndVienna} className="bowl-large" alt="logo" />
            </Col>
          </Row>
        </div>
        <div className="story-section dark">
          <Row>
            <Col className="story-panel" xs={12} md={6}>
              <h2>2. Winning #RebootTheEarth</h2>
              Vienna teamed up with Evan Stites-Clayton, a tech entrepreneur and hacker as well as Iain Nash and Valerie Pang to enter the #RebootTheEarth hackathon in NYC. They built a product concept called "Signs of Life" inspired by Vienna's work and experience. After winning the hackathon, Evan and Vienna went full time on the project, but started from the drawing board, wanting to get real user feedback before settling on a product direction.
            </Col>
            <Col className="story-panel" xs={12} md={6}>
              <img src={rebootWin} className="bowl-large" alt="logo" />
            </Col>
          </Row>
        </div>
        <div className="story-section">
          <Row>
            <Col className="story-panel" xs={12} md={6}>
              <h2>3. Working with Youth Movements</h2>
              <p>As a team, we wanted to align ourselves with the people and groups most actively working to protect our climate. We spent time on the ground with the student strikers of Friday's for the Future, and networked in with the NYC chapter of Extinction Rebellion. While we initially thought we might be able to build tools to help these movements organize, we stumbled upon a new idea in the process.</p>
              <p>Living in NYC after the hackathon, we eventually had a very real need that was going to prevent us from continuing our work in the city: housing. When a retired couple from Extinction Rebellion offered us a guest room in their house we had a lightbulb moment - realizing that if we can connect people with resources that are already their, provided by value aligned donors, it would free them up to work on climate activism and other important causes.</p>
            </Col>
            <Col className="story-panel" xs={12} md={6}>
              <img src={youthPlanning} className="bowl-large" alt="logo" />
            </Col>
          </Row>
        </div>
        <div className="story-section dark">
          <Row>
            <Col className="story-panel" xs={12} md={6}>
              <h2>4. Prototyping with Monastic Academy</h2>
              As we pivoted our idea to building out a free sharing economy for housing, transportation, and time, we received an offer from the Monastic Academy in Vermont to prototype with their community. We worked with their members and members of the local community in a nearby town to collect data on the global temperature impact of these groups and allow them to make offers and requests in a sharing economy.
            </Col>
            <Col className="story-panel" xs={12} md={6}>
              <img src={monasteryWork} className="bowl-large" alt="logo" />
            </Col>
          </Row>
        </div>
        </div>
      </div>
    );
  }
}

export default StoryPage;

import React, { Component } from 'react';
import './Button.css';

export default class TextInput extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    const { onSubmit } = this.props;
    event.preventDefault();
    onSubmit(this.state.value);
    this.setState({
      value: '',
    });
  }
  
  render() {
    const { params } = this.props;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <p><input type="text" value={this.state.value} onChange={this.handleChange} placeholder={params.placeholderText} /></p>
          <p><input className="form-button" type="submit" value={params.submitLabel} /></p>
        </form>
      </div>
    );
  }
}

export const GuideTimerExpires = {
  timerExpires1: {
    text: "Are you still there?",
    options: [
      {
        label: 'I am here now',
        destination: 'welcomeEarly2',
        behavior: 'returnToFlow',
      },
    ],
  },
}
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Moment from 'react-moment';
import '../pages/styles/MatchesPage.css';
import clockIcon from '../assets/micro_clock.png';
import locationIcon from '../assets/location_icon.png';
import bus from '../assets/bus.png';
import clock from '../assets/clock.png';
import house from '../assets/house.png';
import bowl from '../assets/bowl_logo_transp.png';

export default class MatchPreview extends React.Component {
  render() {
    const { offerRequest, matchmaker } = this.props;
    const { createdAt, type, title, description, location, durationType, startDate, endDate, departureDate, returnDate, destination } = offerRequest;

    let graphic = bowl;
    switch(type) {
      case 'shelter':
        graphic = house;
        break;
      case 'transport':
        graphic = bus;
        break;
      case 'time':
        graphic = clock;
        break;
      case 'food':
        graphic = bowl;
        break;
      default:
        break;
    }

    const firstDate = startDate ? startDate : departureDate;
    const lastDate = endDate ? endDate : returnDate;
    const locationToShow = location ? location : destination;

    // If this match was created in the last minute, then we want to show a "highlight" animation on it.
    let rowStyle = "match-preview";
    const oneMinute = 60 * 1000;
    const nowTimestamp = (new Date()).getTime();
    const createdAtTimestamp = createdAt.seconds * 1000;
    if(nowTimestamp - createdAtTimestamp < oneMinute) {
      rowStyle = "match-preview highlight-new";
    }

    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    const isDesktop = width >= 768;

    return (
      <Row className={rowStyle}>
        <Col xs={3} md={2} className="match-image-section">
          <img src={graphic} width="100%" alt="logo" />
        </Col>
        <Col xs={8} md={8} className="match-content-section">
          <p className="match-content-text"><b>{title}</b></p>
          <p className="match-content-text">
            <img src={clockIcon} width="24px" alt="logo" /> 
            { durationType === 'continuous' ? 
              <span>available continuously</span> 
              :
              <span>
                <Moment format="MM/DD/YYYY">{new Date(firstDate.seconds * 1000)}</Moment> until&nbsp;
                <Moment format="MM/DD/YYYY">{new Date(lastDate.seconds * 1000)}</Moment>
              </span>
            }
          </p>
          <p className="match-content-text one-line"><img src={locationIcon} width="20px" alt="logo" /> {locationToShow}</p>
          <p className="match-content-text two-line">{description}</p>
        </Col>
        <Col xs={1} md={2} className="match-link-section">
          {
            isDesktop ? <div>
              <p><button className="form-button edit-view-button" onClick={() => {}}>view</button></p>
              {matchmaker ? <p><button className="form-button edit-view-button" onClick={() => {}}>match</button></p> : 
                <p><button className="form-button edit-view-button" onClick={() => {}}>edit</button></p>
              }
            </div> : <span>&raquo;</span>
          }
        </Col>
      </Row>
    );
  }
}

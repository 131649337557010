export const GuideWelcomeEarly = {
  welcomeEarly1: {
    text: "Hello. Welcome to project bowl. We're so excited to have you here. To fast-forward this text you can click on it. When you are ready to move on, choose an option below.",
    module: "welcomeToBowl",
    step: 1,
    options: [
      {
        label: 'got it',
        destination: 'welcomeEarly2',
      },
    ],
  },
  welcomeEarly2: {
    text: "Our hope is to be a technology platform that you can build deep trust with over time. That said, it would help us if you can create an account now. Are you comfortable signing up?",
    module: "welcomeToBowl",
    step: 2,
    options: [
      {
        label: 'yes',
        destination: 'welcomeEarly3',
      },
      {
        label: 'tell me more about the Bowl trust philosophy first',
        destination: 'trust1',
      },
    ],
  },
  welcomeEarly3a: {
    text: "Ok great, thanks for your trust. What's your email address?",
    module: "welcomeToBowl",
    step: 3,
    textInput: {
      size: 'small',
      placeholderText: 'you@domain.com',
      fieldId: 'userEmail',
      destination: 'welcomeEarly4',
      submitLabel: 'join'
    }
  },
  welcomeEarly3: {
    text: "Sign up using your email via the link below",
    module: "welcomeToBowl",
    step: 3,
    showSignin: true,
    options: [
      {
        label: 'not right now',
        destination: 'welcomeEarly4',
      },
    ],
  },
  welcomeEarly4: {
    text: "Now that your account is set up, we'd like to get some information that will help us understand how we can help you and how you can best help others.",
    module: "welcomeToBowl",
    step: 4,
    options: [
      {
        label: 'ok',
        destination: 'aspirations1',
      },
    ],
  },
  welcomeEarly5: {
    text: "That's all we got for now",
    module: "welcomeToBowl",
    step: 5,
    options: [
      {
        label: 'ok',
        destination: 'welcomeEarly5',
      },
    ],
  },
}
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import firebase from 'firebase';
import bowl from '../../assets/bowl_logo_transp.png';
import bowlWhite from '../../assets/bowl_logo_white_transp.png';
import hamburger from '../../assets/hamburger_black.png';
import hamburgerWhite from '../../assets/hamburger_white.png';
import './Header.css';

export default class Header extends Component { // eslint-disable-line react/prefer-stateless-function
  state = {
    isSignedIn: false // Local signed-in state.
  };
  
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => this.setState({isSignedIn: !!user})
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { darkMode, hideBowl } = this.props;
    const { isSignedIn } = this.state;

    const firebaseSignout = () => (firebase.auth().signOut());

    const lightText = {
      color: '#fff',
    };
    const darkText = {
      color: '#000',
    };
    const textStyle = darkMode ? lightText : darkText;
    const bowlImage = darkMode ? bowlWhite : bowl;
    const hamburgerImage = darkMode ? hamburgerWhite : hamburger;

    const bowlLink = !hideBowl ? <Link to="/guide">
      <img src={bowlImage} className="logo-image logo-two" alt="logo" />
    </Link> : null;

    return (
      <header>
        <a href="#main-menu"
          id="main-menu-toggle"
          className="menu-toggle"
          aria-label="Open main menu">
          <span className="sr-only">Open main menu</span>
          <span className="fa fa-bars" aria-hidden="true"><img src={hamburgerImage} className="logo-image" alt="logo" /></span>
        </a>

        {bowlLink}
        
        <nav id="main-menu" className="main-menu" aria-label="Main menu">
          <a href="#main-menu-toggle"
            id="main-menu-close"
            className="menu-close"
            aria-label="Close main menu">
            <span className="sr-only">Close main menu</span>
            <span className="fa fa-close" aria-hidden="true">X</span>
          </a>
          {isSignedIn ?
            <ul>
              <li><Link style={textStyle} to="/offer">offer</Link></li>
              <li><Link style={textStyle} to="/request">request</Link></li>
              <li><Link style={textStyle} to="/matches">matches</Link></li>
              <li><a href="#" style={textStyle} onClick={() => firebaseSignout()}>sign out</a></li>
            </ul> : <ul>
              <li><Link style={textStyle} to="/vision">vision</Link></li>
              <li><Link style={textStyle} to="/story">story</Link></li>
              <li><Link style={textStyle} to="/old">old site</Link></li>
              <li><Link style={textStyle} to="/login">sign in</Link></li>
            </ul>
          }
        </nav>
        <a href="#main-menu-toggle"
          className="backdrop"
          tabIndex="-1"
          aria-hidden="true" hidden></a>
    </header>
    );
  }
}

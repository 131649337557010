import React, { Component } from 'react';
import firebase from 'firebase';
import DatePicker from "react-datepicker";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import '../styles/OfferRequest.css';
import Header from '../../components/navigation/Header';

const OFFER_TYPE = 'transport';

class TransportOfferPage extends Component {
  constructor(props) {
    super(props);

    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 7);

    this.state = {
      submissionComplete: false,
      user: null,
      title: '',
      durationType: 'once',
      tripType: 'oneWay',
      departureDate: new Date(),
      returnDate: defaultEndDate,
      destination: '',
      origin: '',
      passengerCapacity: '',
      description: '',
      notes: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.setState({
          user: user ? { uid: user.uid, displayName: user.displayName, email: user.email } : null,
        });
      }
    );

    // If there is a prop for the ID of the shelter offer that we are editing
    const editId = this.props.match ? this.props.match.params.editId : null;

    // IF there's an edit ID, look up that offer and load in it's data instead of the blank defaults.
    if (!editId) { return; }
    const offerRef = firebase.firestore().collection('offers').doc(editId);
    offerRef.get().then(offer => {
      if (!offer.exists) {
        console.log('No such offer document!');
      } else {
        console.log('Document data:', offer.data());
        const offerData = offer.data();
        this.setState({
          title: offerData.title,
          tripType: offerData.tripType,
          durationType: offerData.durationType,
          departureDate: new Date(offerData.departureDate.seconds * 1000),
          returnDate: new Date(offerData.returnDate.seconds * 1000),
          destination: offerData.destination,
          origin: offerData.origin,
          passengerCapacity: offerData.passengerCapacity,
          description: offerData.description,
          notes: offerData.notes,
          offerId: offer.id,
        });
      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  handleSubmit(event) {
    event.preventDefault();
    if(!this.state.user) { return }

    let data = Object.assign({}, this.state);
    delete data.user;
    data.userId = this.state.user.uid;
    data.type = OFFER_TYPE;
    data.createdAt = new Date();
    data.updatedAt = new Date();

    const ref = firebase.firestore().collection('offers');
    if(this.state.offerId) {
      // Edit an existing offer
      ref.doc(this.state.offerId).set(data);
    } else {
      ref.add(data);
    }

    this.setState({ submissionComplete: true });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  setDepartureDate = date => {
    this.setState({   
      departureDate: date
    });
  };

  setReturnDate = date => {
    this.setState({   
      returnDate: date
    });
  };

  render() {
    if (this.state.submissionComplete === true) {
      return <Redirect to='/matches' />
    }

    return (
      <div>
        <Header />
        <div className="login-container">
          <p className="offer-request-form-header">{this.state.offerId ? "edit transport offer" : "offer transport"}</p>
          <div className="offer-request-form-wrapper">
            <form onSubmit={this.handleSubmit}>
              <p className="big-label-text">please give a short, descriptive title for this transport offer.</p>
              <p className="offer-request-field">
                <input 
                  name="title" 
                  type="text" 
                  value={this.state.title} 
                  onChange={this.handleInputChange} 
                  placeholder='e.g. Ride to San Francisco this Weekend'
                />
              </p>
              <p className="big-label-text">which best describes the timing in which you can offer transport?</p>
              <p className="offer-request-field">
                <select 
                  name="durationType"
                  value={this.state.durationType}
                  className="select-css"
                  onChange={this.handleInputChange}>
                  <option value="once">Just once, at a specified time</option>
                  <option value="onceAsap">Just once, leaving soon</option>
                  <option value="onceFlexible">Once, and I am flexible on timing</option>
                  <option value="regular">On a regular basis (such as a daily commute)</option>
                </select>
              </p>
              <p className="big-label-text">are you offering a round trip or one way travel?</p>
              <p className="offer-request-field">
                <select 
                  name="tripType"
                  value={this.state.tripType}
                  className="select-css"
                  onChange={this.handleInputChange}>
                  <option value="oneWay">One-way</option>
                  <option value="roundTrip">Round trip</option>
                </select>
              </p>
              {
                this.state.durationType !== 'onceAsap' ? <div>
                  <p className="big-label-text">Around when do you plan to depart?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.departureDate}
                      onChange={date => this.setDepartureDate(date)}
                      showTimeSelect
                      timeFormat="hh:mm aa"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy hh:mm aa"
                    />
                  </div> 
                </div> : null
              }
              {
                this.state.tripType !== 'oneWay' ? <div>
                  <p className="big-label-text">Around when do you plan to return?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.returnDate}
                      onChange={date => this.setReturnDate(date)}
                      minDate={this.state.departureDate}
                      showTimeSelect
                      timeFormat="hh:mm aa"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy hh:mm aa"
                    />
                  </div> 
                </div> : null
              }
              <p className="big-label-text">Where are you going?</p>
              <p className="offer-request-field">
                <input
                  name="destination"
                  type="text"
                  value={this.state.destination}
                  onChange={this.handleInputChange}
                  placeholder='e.g. Los Angeles, CA'
                />
              </p>
              <p className="big-label-text">Where will you be coming from?</p>
              <p className="offer-request-field">
                <input
                  name="origin"
                  type="text"
                  value={this.state.origin}
                  onChange={this.handleInputChange}
                  placeholder='e.g. 2330 Brook Ln. Oakland, CA'
                />
              </p>
              <p className="big-label-text">How many passengers do you have room for?</p>
              <p className="offer-request-field">
                <input
                  name="passengerCapacity"
                  type="text"
                  value={this.state.passengerCapacity}
                  onChange={this.handleInputChange}
                  placeholder='e.g. 3'
                />
              </p>
              <p className="big-label-text">Briefly describe the ride you are offering.</p>
              <p className="offer-request-field">
                <input
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.handleInputChange}
                  placeholder='e.g. heading into SF Sat Nov 6 in the morning, can take 3 people.'
                />
              </p>
              <p className="big-label-text">Is there anything else you want us to know about this you or this traport offer?</p>
              <p className="offer-request-field">
                <input
                  name="notes"
                  type="text"
                  value={this.state.notes}
                  onChange={this.handleInputChange}
                  placeholder='e.g. no smoking in my vehicle.'
                />
              </p>
              <p><input className="form-button offer-request-submit" type="submit" value='submit' /></p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default TransportOfferPage;

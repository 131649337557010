import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Moment from 'react-moment';
import '../pages/styles/MatchesPage.css';
import clockIcon from '../assets/micro_clock.png';
import locationIcon from '../assets/location_icon.png';

export default class OfferRequestDetails extends React.Component {
  render() {
    // const { expanded } = this.state;
    const { offerRequest, noTitle, expanded } = this.props;
    const {
      type,
      title,
      description,
      notes,
      location,
      durationType,
      startDate,
      endDate,
      departureDate,
      returnDate,
      destination,
      origin,
    } = offerRequest;

    let TimeSegment = () => (
      <span>
        <b>available</b> <Moment format="MM/DD/YYYY">{new Date(startDate.seconds * 1000)}</Moment> <b>until</b>&nbsp;
        <Moment format="MM/DD/YYYY">{new Date(endDate.seconds * 1000)}</Moment>
      </span>
    );

    let LocationSegment = () => (
      <p className="match-content-text one-line"><img src={locationIcon} width="20px" alt="logo" /> {location}</p>
    );

    switch(type) {
      case 'shelter':
        break;
      case 'transport':
        TimeSegment = () => (
          <span>
            <b>departing</b> <Moment format="MM/DD/YYYY">{new Date(departureDate.seconds * 1000)}</Moment>
            &nbsp;<b>returning</b> <Moment format="MM/DD/YYYY">{new Date(returnDate.seconds * 1000)}</Moment>
          </span>
        );
        LocationSegment = () => (
          <p className="match-content-text one-line"><img src={locationIcon} width="20px" alt="logo" /> {origin} <b>to</b> {destination}</p>
        );
        break;
      case 'time':
        break;
      case 'food':
        break;
      default:
        break;
    }

    return (
      <div>
        {
          noTitle ? null : <p className="match-content-text"><b>{title}</b></p>
        }
        <p className="match-content-text">
          <img src={clockIcon} width="24px" alt="logo" /> 
          { durationType === 'continuous' ? 
            <span><b>available continuously</b></span> 
            :
            <TimeSegment />
          }
        </p>
        <LocationSegment />
        {
          expanded ? 
            <div>
              <p className="match-content-text"><b>description: </b>{description}</p>
              <p className="match-content-text"><b>notes: </b>{notes}</p> 
            </div>
          :
            <p className="match-content-text two-line">{description}</p>
        }
      </div>
    );
  }
}

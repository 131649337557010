const Modules = {
  welcomeToBowl: {
    title: "Welcome to Bowl",
    steps: 4,
  },
  aspirations: {
    title: "Your Highest Aspiration",
    steps: 8,
  },
  trust: {
    title: "Our Trust Philosophy",
    steps: 10,
  },
}

export default Modules;
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyB15FDn3b2nM1gQGl0Ueat3eChZKVzwknU",
  authDomain: "bowl-early.firebaseapp.com",
  databaseURL: "https://bowl-early.firebaseio.com",
  projectId: "bowl-early",
  storageBucket: "bowl-early.appspot.com",
  messagingSenderId: "257024277141",
  appId: "1:257024277141:web:9e05c87bed4724f799b99c",
  measurementId: "G-7NPKJ6TM9B"
};
var fire = firebase.initializeApp(firebaseConfig);
export default fire;
import React, { Component } from 'react';
import firebase from 'firebase';
import DatePicker from "react-datepicker";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import '../styles/OfferRequest.css';
import Header from '../../components/navigation/Header';

const REQUEST_TYPE = 'food';

class FoodRequestPage extends Component {
  constructor(props) {
    super(props);

    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 7);

    this.state = {
      submissionComplete: false,
      user: null,
      title: '',
      durationType: 'once',
      startDate: new Date(),
      endDate: defaultEndDate,
      maxDays: '',
      location: '',
      description: '',
      notes: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.setState({
          user: user ? { uid: user.uid, displayName: user.displayName, email: user.email } : null,
        });
      }
    );

    // If there is a prop for the ID of the shelter offer that we are editing
    const editId = this.props.match ? this.props.match.params.editId : null;

    // IF there's an edit ID, look up that offer and load in it's data instead of the blank defaults.
    if (!editId) { return; }
    const requestRef = firebase.firestore().collection('requests').doc(editId);
    requestRef.get().then(request => {
      if (!request.exists) {
        console.log('No such request document!');
      } else {
        console.log('Document data:', request.data());
        const requestData = request.data();
        this.setState({
          title: requestData.title,
          durationType: requestData.durationType,
          startDate: new Date(requestData.startDate.seconds * 1000),
          endDate: new Date(requestData.endDate.seconds * 1000),
          maxDays: requestData.maxDays,
          location: requestData.location,
          description: requestData.description,
          notes: requestData.notes,
          requestId: requestRef.id,
        });
      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  handleSubmit(event) {
    event.preventDefault();
    if(!this.state.user) { return }

    let data = Object.assign({}, this.state);
    delete data.user;
    data.userId = this.state.user.uid;
    data.type = REQUEST_TYPE;
    data.createdAt = new Date();
    data.updatedAt = new Date();

    const ref = firebase.firestore().collection('requests');
    if(this.state.requestId) {
      // Edit an existing offer
      ref.doc(this.state.requestId).set(data);
    } else {
      ref.add(data);
    }

    this.setState({ submissionComplete: true });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  setStartDate = date => {
    this.setState({   
      startDate: date
    });
  };

  setEndDate = date => {
    this.setState({   
      endDate: date
    });
  };

  render() {
    if (this.state.submissionComplete === true) {
      return <Redirect to='/matches' />
    }

    return (
      <div>
        <Header />
        <div className="login-container">
          <p className="offer-request-form-header">{this.state.offerId ? "edit food request" : "request food"}</p>
          <div className="offer-request-form-wrapper">
            <form onSubmit={this.handleSubmit}>
              <p className="big-label-text">please give a short, descriptive title for this food request.</p>
              <p className="offer-request-field">
                <input 
                  name="title" 
                  type="text" 
                  value={this.state.title} 
                  onChange={this.handleInputChange} 
                  placeholder='e.g. Dinner in Manhattan Today'
                />
              </p>
              <p className="big-label-text">which best describes the timing in which you need food?</p>
              <p className="offer-request-field">
                <select 
                  name="durationType"
                  value={this.state.durationType}
                  className="select-css"
                  onChange={this.handleInputChange}>
                  <option value="onceAsap">Once, as soon as possible</option>
                  <option value="once">Once, at a certain time</option>
                  <option value="continuous">Food or meals on a regular basis</option>
                </select>
              </p>
              {
                this.state.durationType !== 'continuous' ? <div>
                  <p className="big-label-text">when do you need food?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={date => this.setStartDate(date)}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                    />
                  </div>
                  <p className="big-label-text">Up until what date do you need food?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={date => this.setEndDate(date)}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      minDate={this.state.startDate}
                    />
                  </div> 
                </div> : null
              }
              <p className="big-label-text">Around where are you located when you need food?</p>
              <p className="offer-request-field">
                <input
                  name="location"
                  type="text"
                  value={this.state.location}
                  onChange={this.handleInputChange}
                  placeholder='e.g. 2330 Brook Ln. Oakland, CA'
                />
              </p>
              <p className="big-label-text">Write a short description of your food needs.</p>
              <p className="offer-request-field">
                <input
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.handleInputChange}
                  placeholder='e.g. need lunch or dinner today in Manhattan.'
                />
              </p>
              <p className="big-label-text">Is there anything else you want us to know about your request? Please specify here if you have any allergies.</p>
              <p className="offer-request-field">
                <input
                  name="notes"
                  type="text"
                  value={this.state.notes}
                  onChange={this.handleInputChange}
                  placeholder='e.g. I am allergic to shellfish'
                />
              </p>
              <p><input className="form-button offer-request-submit" type="submit" value='submit' /></p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default FoodRequestPage;

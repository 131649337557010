import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom";
import './styles/OfferRequest.css';
import Header from '../components/navigation/Header';
import bus from '../assets/bus.png';
import clock from '../assets/clock.png';
import house from '../assets/house.png';
import bowl from '../assets/bowl_logo_transp.png';


class OfferPage extends Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    const { onSubmit } = this.props;
    event.preventDefault();
    onSubmit(this.state.value);
  }

  render() {
    const darkText = {
      color: '#000',
    };

    return (
      <div>
        <Header />
        <div className="login-container">
          <p className="offer-request-header">what can you offer?</p>
          <div className="big-button-section">
            <Row>
              <Col className="story-panel" xs={6} md={3}>
                <Link style={darkText} to="/offer/transport">
                  <img src={bus} className="big-button" alt="logo" />
                  <p className="big-label-text">transport</p>
                </Link>
              </Col>
              <Col className="story-panel" xs={6} md={3}>
                <Link style={darkText} to="/offer/shelter">
                  <img src={house} className="big-button" alt="logo" />
                  <p className="big-label-text">shelter</p>
                </Link>
              </Col>
              <Col className="story-panel" xs={6} md={3}>
                <Link style={darkText} to="/offer/food">
                  <img src={bowl} className="big-button slight-buffer" alt="logo" />
                  <p className="big-label-text">food</p>
                </Link>
              </Col>
              <Col className="story-panel" xs={6} md={3}>
                <Link style={darkText} to="/offer/time">
                  <img src={clock} className="big-button" alt="logo" />
                  <p className="big-label-text">time</p>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default OfferPage;

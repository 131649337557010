import React, { Component } from 'react';
import firebase from 'firebase';
import DatePicker from "react-datepicker";
import { Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import '../styles/OfferRequest.css';
import Header from '../../components/navigation/Header';

const REQUEST_TYPE = 'transport';

class TransportRequestPage extends Component {
  constructor(props) {
    super(props);

    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 7);

    this.state = {
      submissionComplete: false,
      user: null,
      title: '',
      durationType: 'once',
      tripType: 'oneWay',
      departureDate: new Date(),
      returnDate: defaultEndDate,
      destination: '',
      origin: '',
      description: '',
      notes: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        this.setState({
          user: user ? { uid: user.uid, displayName: user.displayName, email: user.email } : null,
        });
      }
    );

    // If there is a prop for the ID of the shelter offer that we are editing
    const editId = this.props.match ? this.props.match.params.editId : null;

    // IF there's an edit ID, look up that offer and load in it's data instead of the blank defaults.
    if (!editId) { return; }
    const requestRef = firebase.firestore().collection('requests').doc(editId);
    requestRef.get().then(request => {
      if (!request.exists) {
        console.log('No such request document!');
      } else {
        console.log('Document data:', request.data());
        const requestData = request.data();
        this.setState({
          title: requestData.title,
          durationType: requestData.durationType,
          tripType: requestData.tripType,
          departureDate: new Date(requestData.departureDate.seconds * 1000),
          returnDate: new Date(requestData.returnDate.seconds * 1000),
          destination: requestData.destination,
          origin: requestData.origin,
          description: requestData.description,
          notes: requestData.notes,
          requestId: requestRef.id,
        });
      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  handleSubmit(event) {
    event.preventDefault();
    if(!this.state.user) { return }

    let data = Object.assign({}, this.state);
    delete data.user;
    data.userId = this.state.user.uid;
    data.type = REQUEST_TYPE;
    data.createdAt = new Date();
    data.updatedAt = new Date();

    const ref = firebase.firestore().collection('requests');
    if(this.state.requestId) {
      // Edit an existing offer
      ref.doc(this.state.requestId).set(data);
    } else {
      ref.add(data);
    }

    this.setState({ submissionComplete: true });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  setDepartureDate = date => {
    this.setState({   
      departureDate: date
    });
  };

  setReturnDate = date => {
    this.setState({   
      returnDate: date
    });
  };

  render() {
    if (this.state.submissionComplete === true) {
      return <Redirect to='/matches' />
    }

    return (
      <div>
        <Header />
        <div className="login-container">
          <p className="offer-request-form-header">{this.state.requestId ? "edit transport request" : "request transport"}</p>
          <div className="offer-request-form-wrapper">
            <form onSubmit={this.handleSubmit}>
              <p className="big-label-text">please give a short, descriptive title for this transport request.</p>
              <p className="offer-request-field">
                <input 
                  name="title" 
                  type="text" 
                  value={this.state.title} 
                  onChange={this.handleInputChange} 
                  placeholder='e.g. Ride to Los Angeles'
                />
              </p>
              <p className="big-label-text">which best describes the timing in which you need transport?</p>
              <p className="offer-request-field">
                <select 
                  name="durationType"
                  value={this.state.durationType}
                  className="select-css"
                  onChange={this.handleInputChange}>
                  <option value="once">Just once, at a specified time</option>
                  <option value="onceAsap">Just once, as soon as possible</option>
                  <option value="onceFlexible">Once, and I am flexible on timing</option>
                  <option value="regular">On a regular basis (such as a daily commute)</option>
                </select>
              </p>
              <p className="big-label-text">are you looking for round trip or one way travel?</p>
              <p className="offer-request-field">
                <select 
                  name="tripType"
                  value={this.state.tripType}
                  className="select-css"
                  onChange={this.handleInputChange}>
                  <option value="oneWay">One-way</option>
                  <option value="roundTrip">Round trip</option>
                  <option value="roundTripFlexible">Round trip ideally, but I'm flexible.</option>
                </select>
              </p>
              {
                this.state.durationType !== 'onceAsap' ? <div>
                  <p className="big-label-text">Around when do you want to depart?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.departureDate}
                      onChange={date => this.setDepartureDate(date)}
                    />
                  </div> 
                </div> : null
              }
              {
                this.state.tripType !== 'oneWay' ? <div>
                  <p className="big-label-text">Around when do you want to return?</p>
                  <div className="offer-request-field">
                    <DatePicker
                      selected={this.state.returnDate}
                      onChange={date => this.setReturnDate(date)}
                      minDate={this.state.departureDate}
                    />
                  </div> 
                </div> : null
              }
              <p className="big-label-text">Where are you going?</p>
              <p className="offer-request-field">
                <input
                  name="destination"
                  type="text"
                  value={this.state.destination}
                  onChange={this.handleInputChange}
                  placeholder='e.g. Los Angeles, CA'
                />
              </p>
              <p className="big-label-text">Where will you be coming from?</p>
              <p className="offer-request-field">
                <input
                  name="origin"
                  type="text"
                  value={this.state.origin}
                  onChange={this.handleInputChange}
                  placeholder='e.g. 2330 Brook Ln. Oakland, CA'
                />
              </p>
              <p className="big-label-text">Briefly describe the ideal ride you are requesting.</p>
              <p className="offer-request-field">
                <input
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.handleInputChange}
                  placeholder='e.g. a ride to LA sometime next week, I have a small luggage.'
                />
              </p>
              <p className="big-label-text">Is there anything else you want us to know about this you or this request?</p>
              <p className="offer-request-field">
                <input
                  name="notes"
                  type="text"
                  value={this.state.notes}
                  onChange={this.handleInputChange}
                  placeholder='e.g. hoping to ride with a non-smoker'
                />
              </p>
              <p><input className="form-button offer-request-submit" type="submit" value='submit' /></p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default TransportRequestPage;

import React from 'react';

export default class AnimatedText extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      currChar: 0,
      text: props.children || "default",
      animating: false,
    };
  }

  componentDidMount() {
    this.animate();
  }

  animate() {
    // Don't animate if we are already animating, should stop in between each time.
    if(this.state.animating) { return }

    this.setState({animating: true});
    this.props.onAnimationRolling();

    var animation = setInterval(() => {
      this.setState(previousState => (
        { currChar: previousState.currChar + 1 }
      ));
      
      if(this.state.currChar >= this.state.text.length) {
        clearInterval(animation);
        this.setState({animating: false});
        this.props.onAnimationComplete();
      }
    }, 90);
  }

  completeAnimation() {
    this.setState({ 
      currChar: this.state.text.length,
    });
  }

  newText(text) {
    this.setState({ 
      currChar: 0,
      text: text
    });

    this.animate();
  }

  render() {
    var overallString = this.state.text
    var overallStringLength = this.state.text.length

    var visibleString = "";
    if(overallString.substring(0, this.state.currChar).length > 0) {
      visibleString = overallString.substring(0, this.state.currChar);
    }

    var invisibleString = "";
    if(this.state.currChar < overallStringLength) {
      invisibleString = overallString.substring(this.state.currChar, overallStringLength);
    }

    const visibleText = {
      color: '#000',
    };
    const invisibleText = {
      color: '#fff',
    };

    return (
      <span style={visibleText}>
        {visibleString}
        <span style={invisibleText}>
          {invisibleString}
        </span>
      </span>
    );
  }
}
